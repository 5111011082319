import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

// Icons
import { CloseIconMobile } from "../icons/close-icon";

const CookieContainer = styled.div`
  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    font-size: 15px;
    line-height: 20px;

    background-color: #fff;

    padding: 20px 60px;

    @media (max-width: 1700px) {
      padding: 20px 40px;
    }

    @media (max-width: 1024px) {
      padding: 20px;
    }
  }

  & .content-container {
    max-width: calc(100% - 50px);
  }

  & .cookie-text > p {
    margin: 0;
    color: #000;

    font-size: 15px;
    line-height: 20px;

    & a {
      text-decoration: underline;
    }
  }

  & .button-container {
    width: 14px;
    height: 14px;

    & .cookie-button {
      order: 1;

      padding: 0;
      margin: 0;

      cursor: pointer;

      @media (max-width: 600px) {
        order: 12;
      }
    }
  }
`;

export const Cookies = ({ location, color, bgColor }) => {
  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          cookie_text {
            html
          }
        }
      }
    }
  `);

  return (
    <CookieContainer>
      <CookieConsent
        location="bottom"
        buttonText={<CloseIconMobile />}
        cookieName="mcmullan-studio-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div
          className="cookie-text"
          dangerouslySetInnerHTML={{
            __html: data.prismicSettings.data.cookie_text.html,
          }}
        />
      </CookieConsent>
    </CookieContainer>
  );
};
