import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  // width: 250px;
  // width: 216px;
  height: auto;
  display: block;

  transition: 350ms width ease;
`;

export const Logo = () => (
  <Icon
    width="215"
    height="58"
    viewBox="0 0 215 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="logo"
  >
    <path
      d="M0 0.513184H4.76067L13.4411 20.0405L22.1642 0.513184H26.9534V24.958H22.6346V9.39312L15.7216 24.958H11.189L4.30456 9.39312V24.958H0V0.513184Z"
      fill="black"
    />
    <path
      d="M29.9609 12.8709C29.9609 5.37357 35.4913 0 44.1147 0C51.4125 0 56.558 4.34732 57.399 9.80642H52.8663C52.0966 6.4996 48.6188 3.89121 43.8581 3.89121C38.1282 3.89121 34.5363 7.65414 34.5363 12.7426C34.5363 17.8597 38.171 21.5941 43.9294 21.5941C48.6901 21.5941 52.1252 18.9287 52.8663 15.6789H57.399C56.5295 21.1807 51.384 25.4853 43.9864 25.4853C35.3345 25.471 29.9609 20.1402 29.9609 12.8709Z"
      fill="black"
    />
    <path
      d="M60.5635 0.513184H65.3241L74.0045 20.0405L82.7277 0.513184H87.5169V24.958H83.2123V9.39312L76.2994 24.958H71.7667L64.868 9.39312V24.958H60.5635V0.513184V0.513184Z"
      fill="black"
    />
    <path
      d="M91.835 14.2536V0.513184H96.1395V13.0278C96.1395 17.988 96.6527 21.5799 103.095 21.5799C109.524 21.5799 110.008 17.9737 110.008 13.0278V0.513184H114.341V14.2536C114.341 20.7247 112.987 25.4853 103.081 25.4853C93.1463 25.4711 91.835 20.5821 91.835 14.2536Z"
      fill="black"
    />
    <path
      d="M138.487 20.7532V24.9722H118.96V0.513184H123.236V20.7532H138.487V20.7532Z"
      fill="black"
    />
    <path
      d="M160.979 20.7532V24.9722H141.452V0.513184H145.728V20.7532H160.979V20.7532Z"
      fill="black"
    />
    <path
      d="M174.107 0.513184H178.739L190.313 24.958H185.581L182.531 18.7862H170.301L167.208 24.958H162.49L174.107 0.513184ZM181.02 14.7667L176.416 5.50191L171.784 14.7667H181.02Z"
      fill="black"
    />
    <path
      d="M192.479 0.513184H196.727L210.653 18.0165V0.513184H215V24.958H210.553L196.784 8.13881V24.958H192.479V0.513184Z"
      fill="black"
    />
    <path
      d="M1.4541 49.2316H6.05799C7.15551 52.7665 10.4338 53.8925 14.9094 53.8925C18.4871 53.8925 20.7961 52.8663 20.7961 50.8423C20.7961 48.69 18.0309 47.8775 15.9357 47.3359C14.04 46.8513 11.7879 46.4664 9.17951 45.6682C5.64464 44.5422 3.03624 42.9316 3.03624 39.4537C3.00773 34.1086 8.69489 32.1844 13.6266 32.1844C20.4398 32.1844 24.1457 35.534 24.3025 40.3517H19.7984C19.4135 36.7455 16.0069 35.9473 13.2418 35.9473C11.4458 35.9473 7.61162 36.3607 7.61162 39.0689C7.58311 40.6082 8.70914 41.4207 11.1465 42.0621C12.8854 42.5467 13.9829 42.8033 16.4203 43.4162C18.8291 43.9578 25.0437 44.9983 25.0437 50.5287C25.0437 55.3891 21.3805 57.6697 14.3963 57.6697C5.75866 57.6554 1.92447 54.0208 1.4541 49.2316ZM39.2544 36.9023V57.1423H34.9071V36.9023H25.9701V32.6833H48.1629L48.1914 36.9023H39.2544V36.9023ZM50.871 46.4237V32.6833H55.1756V45.1979C55.1756 50.1581 55.6887 53.75 62.1313 53.75C68.5597 53.75 69.0443 50.1438 69.0443 45.1979V32.6833H73.3916V46.4237C73.3916 52.8948 72.0375 57.6555 62.1313 57.6555C52.1966 57.6555 50.871 52.7665 50.871 46.4237ZM77.9955 57.1423V32.6975H89.0277C97.2235 32.6975 102.34 36.1754 102.34 44.9271C102.34 53.1941 98.2212 57.1851 89.5408 57.1851L77.9955 57.1423ZM88.7426 53.2511C94.3015 53.2511 97.7794 51.1558 97.7794 44.9128C97.7794 39.1259 94.9429 36.5888 88.7426 36.5888H82.3143V53.2511H88.7426ZM104.806 52.9233H113.743V36.9023H104.806V32.6833H126.999L127.028 36.9023H118.091V52.9233H127.028L126.999 57.1423H104.806V52.9233ZM129.123 44.9128C129.123 37.5722 134.724 32.1702 143.433 32.1702C152.157 32.1702 157.687 37.5722 157.687 44.9128C157.687 52.2534 152.157 57.6555 143.433 57.6555C134.724 57.6555 129.123 52.2534 129.123 44.9128ZM153.097 44.9128C153.097 40.0524 149.391 36.0614 143.419 36.0614C137.433 36.0614 133.741 40.0524 133.741 44.9128C133.741 49.7733 137.447 53.7642 143.419 53.7642C149.391 53.7642 153.097 49.7733 153.097 44.9128Z"
      fill="black"
    />
  </Icon>
);
