import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { useStaticQuery, graphql } from "gatsby";

// Context
import { PageTitle } from "../context/page-title";
import { PageType } from "../context/page-type";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { DefaultSEO } from "./default-seo";
import { Cookies } from "./cookies";
import { Header } from "./header";
import { Footer } from "./footer";

// Transition
// import { Transition } from "../transition/transition";

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;

  &.custom-cursor {
    cursor: url(/icons/cursor@1x.png) 10 10, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor@1x.png) 1x,
          url(/icons/cursor@2x.png) 2x
        )
        10 10,
      pointer !important;

    & button,
    & a,
    & img.clickable {
      cursor: url(/icons/cursor@1x.png) 10 10, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor@1x.png) 1x,
            url(/icons/cursor@2x.png) 2x
          )
          10 10,
        pointer !important;
    }
  }

  & .highlight-color,
  & .project-content-container {
    &:hover {
      font-family: "Surt-Semi-Bold", "Helvetica Neue", "Lucida Grande",
        sans-serif;
    }

    & button {
      &:hover {
        font-family: "Surt-Semi-Bold", "Helvetica Neue", "Lucida Grande",
          sans-serif;
      }
    }
  }

  & .active-filter {
    & button {
      font-family: "Surt-Semi-Bold", "Helvetica Neue", "Lucida Grande",
        sans-serif;
    }
  }

  & .inline-link {
    & a {
      &:hover {
        font-family: "Surt-Semi-Bold", "Helvetica Neue", "Lucida Grande",
          sans-serif;
      }
    }
  }
`;

const Main = styled.main`
  flex: 1;

  padding: 150px 60px 0 60px;

  @media (max-width: 1700px) {
    padding: 150px 40px 0 40px;
  }

  @media (max-width: 1024px) {
    padding: 150px 20px 0 20px;
  }

  @media (max-width: 860px) {
    padding: 0 20px;
  }
`;

const Layout = ({ children }) => {
  const [pageTitle, setPageTitle] = useState(null);
  const [pageType, setPageType] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          display_custom_cursor
          highlight_color
        }
      }
    }
  `);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PageType.Provider value={[pageType, setPageType]}>
      <PageTitle.Provider value={[pageTitle, setPageTitle]}>
        <Location>
          {({ location }) => (
            <>
              <Normalize />
              <GlobalStyles />
              <DefaultSEO location={location} />

              <Container
                className={
                  data.prismicSettings.data.display_custom_cursor
                    ? `custom-cursor`
                    : ``
                }
                highlight={data.prismicSettings.data.highlight_color}
              >
                <Header
                  location={location}
                  pageTitle={pageTitle}
                  pageType={pageType}
                />
                <Main>{children}</Main>

                {(pageType === "home" || pageType === "about") && <Footer />}
              </Container>

              <Cookies location={location} />
            </>
          )}
        </Location>
      </PageTitle.Provider>
    </PageType.Provider>
  );
};

export default Layout;
