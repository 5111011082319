import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  z-index: 30;

  @media (max-width: 600px) {
    width: 16px;
    height: 16px;
  }
`;

export const CloseIcon = () => (
  <Icon
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L25 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M25 1L0.999999 25"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Icon>
);

const MobileIcon = styled.svg``;

export const CloseIconMobile = () => (
  <MobileIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L13 13"
      stroke="black"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M13 1L1 13"
      stroke="black"
      stroke-width="1.6"
      stroke-linecap="round"
    />
  </MobileIcon>
);
