import React, { useState, useEffect } from "react";

export const MobileMenu = ({ pageType, menuItems, location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <>
      {pageType !== `article` && (
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="menu-toggle"
        >
          {isMenuOpen ? `Close` : `Menu`}
        </button>
      )}
      {pageType !== `article` && isMenuOpen && <ol>{menuItems}</ol>}
    </>
  );
};
