import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import Media from "react-media";

import { useWindowScroll } from "react-use";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

// Icons
import { Logo } from "../icons/logo";
import { OneLinerLogo } from "../icons/one-liner-logo";
import { CloseIcon } from "../icons/close-icon";
// import { SmallLogo } from "../icons/small-logo";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 33px 60px 35px 60px;
  height: 70px;

  background-color: #fff;
  z-index: 200;

  transition: 200ms padding linear, 200ms height linear;

  &.small {
    padding: 15px 60px;
    height: 40px;

    & .hide-title-on-scroll {
      & h1 {
        display: none;
      }
    }

    @media (max-width: 1700px) {
      padding: 15px 40px;
    }

    @media (max-width: 1024px) {
      padding: 15px 20px;
    }

    @media (max-width: 860px) {
      height: auto;
      padding: 15px 20px;
    }

    & nav {
      & ol {
        & li {
          & a {
            font-size: 24px;
            line-height: 130%;

            @media (max-width: 860px) {
              font-size: 18px;
              line-height: 36px;
            }
          }
        }
      }
    }

    & h1,
    & h2 {
      font-size: 24px;
      line-height: 130%;

      @media (max-width: 860px) {
        font-size: 18px;
      }
    }

    & svg.logo {
      width: 150px;
      margin: 0 60px 0 0;

      @media (max-width: 860px) {
        width: 216px;
      }
    }

    & svg.small-logo {
      width: 18.8px;

      @media (max-width: 600px) {
        width: 15px;
      }
    }
  }

  @media (max-width: 1700px) {
    padding: 35px 40px;
  }

  @media (max-width: 1370px) {
    padding: 20px 40px;
    height: 60px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 860px) {
    height: auto;

    padding: 15px 20px;
  }

  & nav {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .menu-toggle {
      line-height: 1;
      height: 16px;

      z-index: 10;
    }

    & .journal-close-icon {
      height: 27px;
      display: block;

      @media (max-width: 600px) {
        height: 16px;
      }
    }

    & ol {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 860px) {
        display: block;
        flex-direction: unset;
        justify-content: unset;
        align-items: unset;

        position: absolute;
        top: 10px;

        left: 0;
        right: 0;
        padding: 20px 0;

        background-color: #fff;
        z-index: 1;
      }

      & li {
        padding: 0 60px 0 0;

        &:last-of-type {
          padding: 0;
        }

        & a {
          font-size: 32px;
          line-height: 38px;
          position: relative;

          transition: 200ms font-size linear, 200ms line-height linear;

          @media (max-width: 1024px) {
            font-size: 24px;
            line-height: 130%;
          }

          @media (max-width: 860px) {
            font-size: 18px;
            line-height: 36px;
          }

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            bottom: -5px;
            left: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            background-color: #000;
          }

          &:hover {
            &::before {
              visibility: visible;
              width: 100%;
            }
          }

          &.current {
            &::before {
              visibility: visible;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  align-items: center;

  position: relative;
  width: 100%;

  z-index: 10;

  & .logo-container {
    & .logo {
      display: block;
      margin: 0 60px 0 0;

      width: 250px;

      @media (max-width: 1370px) {
        width: 215px;
      }
    }

    & .small-logo {
      display: none;
    }
  }

  &.hide-title-on-scroll {
    & h1 {
      max-width: calc(100% - 335px);
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
  }

  &.main-page-title {
    & .logo-container {
      transition: 250ms transform ease, 250ms opacity ease;
      transform: translateY(0);
      opacity: 1;

      position: absolute;
      left: 0;
    }

    & h1,
    & h2 {
      transition: 250ms transform ease, 250ms opacity ease;
      transform: translateY(200%);
      opacity: 0;
      position: absolute;
      left: 0;
    }

    &.active-title {
      & .logo-container {
        transform: translateY(-100%);
        opacity: 0;
      }

      & h1,
      & h2 {
        transform: translateY(0);
        opacity: 1;

        max-width: calc(100% - 60px);
        line-height: 30px;

        @media (max-width: 860px) {
          line-height: 24px;
        }
      }
    }
  }
`;

export const Header = ({ location, pageTitle, pageType }) => {
  const [journalFilters, setJournalFilters] = useState(null);
  const [headerSize, setHeaderSize] = useState(``);

  const [windowWidth, setWindowWidth] = useState(860);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  const { x, y } = useWindowScroll();

  useEffect(() => {
    if (y >= 90) {
      setHeaderSize(`small`);
    } else {
      setHeaderSize(``);
    }
  }, [y]);

  useEffect(() => {
    if (
      location !== undefined &&
      location !== null &&
      location.state !== undefined &&
      location.state !== null &&
      location.state.filters !== undefined &&
      location.state.filters !== null
    ) {
      setJournalFilters(location.state.filters);
    } else {
      setJournalFilters(null);
    }
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          header_menu {
            item {
              document {
                ... on PrismicAbout {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicJournal {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicProjects {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = data.prismicSettings.data.header_menu.map((item, index) => (
    <li key={`single_menu_item_${item.item.document.id}_${index}`}>
      <Link
        to={item.item.document.url}
        activeClassName="current"
        partiallyActive={true}
      >
        {item.item.document.data.title.text}
      </Link>
    </li>
  ));

  return (
    <HeaderWrapper className={headerSize}>
      <nav>
        {pageType !== `project` && pageType !== `article` && (
          <TitleContainer
            className={
              location.pathname === `/` && pageTitle !== null
                ? `main-page-title active-title`
                : `main-page-title `
            }
          >
            <div className="logo-container">
              <Link to={`/`}>
                <Media
                  queries={{ medium: "(max-width: 860px)" }}
                  defaultMatches={{ medium: windowWidth === 860 }}
                  render={() => <OneLinerLogo />}
                />
                <Media
                  queries={{ medium: "(min-width: 861px)" }}
                  defaultMatches={{ medium: windowWidth === 861 }}
                  render={() => <Logo />}
                />
              </Link>
            </div>

            {location.pathname === `/` && <h2>{pageTitle}</h2>}
          </TitleContainer>
        )}

        {(pageType === `project` || pageType === `article`) && (
          <TitleContainer className="hide-title-on-scroll">
            <div className="logo-container">
              <Link to={`/`}>
                <Media
                  queries={{ medium: "(max-width: 860px)" }}
                  defaultMatches={{ medium: windowWidth === 860 }}
                  render={() => <OneLinerLogo />}
                />
                <Media
                  queries={{ medium: "(min-width: 861px)" }}
                  defaultMatches={{ medium: windowWidth === 861 }}
                  render={() => <Logo />}
                />
              </Link>
            </div>

            <Media
              queries={{ medium: "(min-width: 861px)" }}
              defaultMatches={{ medium: windowWidth === 861 }}
              render={() => <h1>{pageTitle}</h1>}
            />
          </TitleContainer>
        )}

        <Media
          queries={{ medium: "(max-width: 860px)" }}
          defaultMatches={{ medium: windowWidth === 860 }}
          render={() => (
            <MobileMenu
              menuItems={menuItems}
              pageType={pageType}
              location={location}
            />
          )}
        />
        <Media
          queries={{ medium: "(min-width: 861px)" }}
          defaultMatches={{ medium: windowWidth === 861 }}
          render={() => (
            <DesktopMenu menuItems={menuItems} pageType={pageType} />
          )}
        />

        {pageType === `article` && (
          <Link
            to={`/journal`}
            state={{ filters: journalFilters }}
            className="journal-close-icon"
          >
            <CloseIcon />
          </Link>
        )}
      </nav>
    </HeaderWrapper>
  );
};
