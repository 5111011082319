import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import styled from "styled-components";

const FooterWrapper = styled.footer`
  padding: 40px 60px;

  @media (max-width: 1700px) {
    padding: 40px;
  }

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }

  & .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 860px) {
      display: block;
      flex-direction: unset;
      flex-wrap: unset;
      justify-content: unset;

      & .text-container.right {
        margin: 1em 0 0 0;
      }
    }

    @media (max-width: 600px) {
      & .text-container.left {
        max-width: 280px;
      }
    }
  }

  & p {
    font-size: 15px;
    line-height: 20px;

    margin: 0;

    & a {
      border-bottom: 0;
    }
  }

  & .credits-container {
    margin: 1em 0 0 0;
    text-align: left;
  }
`;

export const Footer = () => {
  const [year, setYear] = useState(2021);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  const data = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          text_left {
            html
          }
          text_right {
            html
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <div className="flex-container">
        <div
          className="text-container left"
          dangerouslySetInnerHTML={{
            __html: data.prismicSettings.data.text_left.html,
          }}
        />

        <div className="text-container right">
          <p className="tnum">Copyright Mcmullan Studio Ltd. {year}</p>
          <div
            className="inline-link"
            dangerouslySetInnerHTML={{
              __html: data.prismicSettings.data.text_right.html,
            }}
          />
        </div>
      </div>

      <div className="credits-container">
        <p>
          <a href="https://kieranstartup.co.uk/" target="_blank" rel="noopener">
            Site Credits
          </a>
        </p>
      </div>
    </FooterWrapper>
  );
};
